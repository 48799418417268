import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import get from "lodash/get"

import Seo from "../components/Seo"
import CampaignHero from "../components/Hero/CampaignHero"
import PageLayout from "../components/PageLayout/PageLayout"

import { getPlainText, getPrettyURL } from "../utils"
import { SeoDataModel } from "../models/common"
import { CampaignModel } from "../models/pages"

import { fetchLivePreviewData, contentstackLivePreviewWrapper } from "../live-preview"

const defaultProps = {}

export const CampaignPage: React.FC<CampaignModel> = props => {
  // page data
  const _page =  get(props, "data.page") as any as CampaignModel;
  _page.pageContext = props.pageContext
  const [page, setPage] = useState(_page);

    useEffect(() => {
      contentstackLivePreviewWrapper(fetchLivePreviewData(_page, setPage, props.pageContext))
  }, [])

  // page heading
  const heroHeading = getPlainText(page.hero.heading)

  // SEO Fields
  const seoData: SeoDataModel = {
    metaTitle: page.seo?.title || heroHeading,
    metaDescription:
      page.seo?.description || getPlainText(page.listing?.excerpt),
    metaLink: page.seo?.canonical_link,
    metaKeywords: page.seo?.keywords,
    metaImage: page.seo?.share_image,
    slug: `/${props.pageContext.language}/${getPrettyURL(page)}`,
    locale: props.pageContext.locale,
    pageContext: props.pageContext,
  }

  return (
    <div className={`campaignPage`}>
      <Seo {...seoData} />

      {/* Page Hero */}
      <CampaignHero
        heading={heroHeading}
        heroImage={page.hero.hero_image}
        imageFocus={page.hero.image_focus}
        bannerText={page.banner_text}
      />

      {/* Content */}
      {page.body?.map((layout, index) => (
        <PageLayout
          key={`layout_${index}`}
          {...layout}
          fullWidth
          pageContext={props.pageContext}
        />
      ))}
    </div>
  )
}

CampaignPage.defaultProps = defaultProps

export default CampaignPage

/****
	Data
****/
export const query = graphql`
  query CampaignPageByID($pageId: String!) {
    page: contentstackCampaign(id: { eq: $pageId }) {
      __typename
      uid
      ...CampaignFields
      hero {
        hide_breadcrumbs
        hero_image {
          ...CmsImageFields
          gatsbyImageData(width: 1360, quality: 95)
        }
      }
      body {
        body {
          rich_text {
            rich_text
          }
          html_text {
            html
          }
          columns {
            separator
            col_1_text
            column_1_promo_links
            col_1_components {
              ... on Contentstack_button {
                ...ButtonFields
              }
              ... on Contentstack_copy {
                ...CopyFields
              }
              ... on Contentstack_media {
                ...MediaFields
              }
              ... on Contentstack_video {
                ...VideoFields
              }
            }
            col_2_text
            column_2_promo_links
            col_2_components {
              ... on Contentstack_button {
                ...ButtonFields
              }
              ... on Contentstack_copy {
                ...CopyFields
              }
              ... on Contentstack_media {
                ...MediaFields
              }
              ... on Contentstack_video {
                ...VideoFields
              }
            }
          }
          banner {
            banner {
              ...BannerFields
            }
          }
          button {
            label
            anchor {
              ...AnchorFields
            }
            button {
              ...ButtonFields
            }
          }
          copy {
            copy {
              ...CopyFields
            }
          }
          form {
            forms {
              tab_heading
              form_type
              content_before
              content_after
            }
          }
          image {
            image {
              ...CmsImageFields
              gatsbyImageData(quality: 95)
            }
            alt_text
            caption
            anchor {
              ...AnchorFields
            }
          }
          image_slider {
            heading
            center_title
            slides {
              slide_name
              show_name
              image {
                ...CmsImageFields
                gatsbyImageData(quality: 95)
              }
              url
              entry {
                ... on Node {
                  ...AllPageLinks
                }
              }
              asset {
                ...CmsAssetFields
              }
              new_window
            }
          }
          listing {
            listing_items {
              ... on Contentstack_article {
                ...ArticleFields
              }
              ... on Contentstack_campaign {
                ...CampaignFields
              }
              ... on Contentstack_page {
                ...ContentPageFields
              }
            }
            listing_type
            root_page {
              ... on Contentstack_page {
                id
              }
              ... on Contentstack_recipe_listing {
                id
              }
            }
            display_all_children
          }
          video {
            video_url
            caption
          }
          recipe_collection {
            center_title_text
            number_of_records
            title
            collection {
              hero {
                heading
              }
              recipes {
                ...RecipeFields
              }
            }
          }
        }
        sidebar {
          rich_text {
            rich_text
          }
          banner {
            banner {
              ...BannerFields
            }
          }
          button {
            label
            anchor {
              ...AnchorFields
            }
            button {
              ...ButtonFields
            }
          }
          copy {
            copy {
              ...CopyFields
            }
          }
          image {
            image {
              ...CmsImageFields
              gatsbyImageData(quality: 95)
            }
            alt_text
            caption
            anchor {
              ...AnchorFields
            }
          }
          menu {
            navigation_group {
              ...NavGroupFields
            }
          }
        }
        blocks {
          ...AllBlocks
        }
      }
      seo {
        title
        description
        canonical_link
        keywords
        share_image {
          ...CmsImageFields
          gatsbyImageData(quality: 95)
        }
      }
    }
  }

  fragment CampaignFields on Contentstack_campaign {
    id
    url
    url_structure {
      ...UrlStructureFields
    }
    nav_label
    hero {
      heading
      image_focus
      listing_img: hero_image {
        ...CmsImageFields
        #gatsbyImageData(width: 590,  quality: 95)
        gatsbyImageData(height: 230, quality: 95)
      }
      mini_img: hero_image {
        ...CmsImageFields
        gatsbyImageData(height: 75, quality: 95)
      }
    }
    banner_text
    listing {
      excerpt
      listing_img: listing_image {
        ...CmsImageFields
        #gatsbyImageData(width: 590,  quality: 95)
        gatsbyImageData(height: 230, quality: 95)
      }
      mini_img: listing_image {
        ...CmsImageFields
        gatsbyImageData(height: 75, quality: 95)
      }
      darken_image
      listing_color
      listing_width
      promo_size
    }
    internal {
      type
    }
    publish_details {
      locale
    }
  }
`