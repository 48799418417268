import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import { isEmptyRichText } from "../../utils"
import { CmsImage } from "../../models/common"

interface Props {
  heading?: string
  heroImage?: CmsImage
  imageFocus?: string
  bannerText?: string
}

const defaultProps = {}

const CampaignHero: React.FC<Props> = props => {
  return (
    <section className="campaignHero">
      <div className="grid-container" style={{ position: "relative" }}>
        {props.heroImage?.gatsbyImageData && (
          <GatsbyImage
            image={props.heroImage?.gatsbyImageData}
            alt=""
            className="hero__image"
          />
        )}

        <div className="grid-x align-middle hero__inner">
          <div className="cell">
            {!isEmptyRichText(props.heading) && (
              <div className="hero__content">
                <h2>{props.heading}</h2>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="hero__banner">
        <div className="grid-container hero__bannerWrapper">
          <div className="hero__bannerInner">{props.bannerText}</div>
        </div>
      </div>
    </section>
  )
}

CampaignHero.defaultProps = defaultProps

export default CampaignHero
